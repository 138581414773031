import React, { Component } from "react"
import PropTypes from "prop-types"
import Title from "../components/title"
import styles from "../styles/scss/components/partners.module.scss"
import Imgix from "react-imgix"

class Partners2024 extends Component {
    constructor(props) {
        super()
    }
    static defaultProps = {}

    render() {
        const { title, logos } = this.props

        return (
            <div className={`${styles.partnersMainWrapper}`}>
                <Title className={`${styles.partnersMainWrapper_title}`}>
                    {title}
                </Title>
                <div className={`${styles.partnersMainWrapper_logos}`}>
                    {logos.map((logo, key) => (
                        <div
                            key={key}
                            className={`${styles.partnerslogo2024}`}
                            type="image/svg+xml"
                            data={logo.src}
                        >
                            <Imgix src={logo.src} alt={logo.alt} htmlAttributes={{ alt: logo.alt }} />
                        </div>
                    ))}
                </div>
            </div>
        )
    }
}

Partners2024.propTypes = {
    title: PropTypes.string.isRequired,
    logos: PropTypes.array.isRequired,
    isMobile: PropTypes.bool,
}

export default Partners2024
