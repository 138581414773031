import React from "react"
import NewLayout from "../layout/newLayout"
import SEO from "../components/seo"
import cn from "classnames"
import { supSub } from "../services/util"
import styles from "../styles/scss/templates/sema.module.scss"
import Button from '../components/button'
import CtaBanner from "../components/ctaBanner"
import Partners2024 from "../components/partners2024"
import GallerySema2024 from "../components/gallerySema2024"
import HeroCarousel2024 from "../components/heroCarousel2024"

import { isMobile } from "react-device-detect"
import {
  partnersData2024,
  galleryData2024,
} from "../../static/img/sema-building/semaBuildingData"
import Imgix from "react-imgix"
import VideoBlock from '../components/videoBlock'

const Sema2024 = ({ ...props }) => {
  const scrollFun = id => {
    return document
      .querySelector(`#${id}`)
      .scrollIntoView({ block: "center", behavior: "smooth" })
  }

  // Context/Data of the Page
  const pageData = props.pageContext.page
  // End of Context/Data

  const { seo } = pageData
  const { title: partnersTitle, logos } = partnersData2024
  const pageTitle = seo.description

  const firstSectionContent = {
    video: pageData.content[0].video,
    title: pageData.content[0].title
  }

  const seventhSection = {
    imageUrl: pageData.content[1].image.url,
    text: pageData.content[1].body,
  }

  const tableData = [
    { date: 'APRIL 26 – MAY 4', name: 'Norra 1000', location: 'Baja, MX' },
    { date: 'AUGUST 15 – 17', name: 'Ace Show', location: 'Chicago, IL' },
    { date: 'AUGUST 30 – 31', name: 'Crandon Polaris World Champs', location: 'Crandon, WI' },
    { date: 'NOVEMBER 5 – 8', name: 'SEMA', location: 'Las Vegas, NV' },
    { date: 'NOVEMBER 12 - 17', name: 'SCORE Baja 1000', location: 'Baja, MX' }
  ];

  const tempContent = '<p><span>After acquiring a 2006 mil-spec AM General Humvee, work began in earnest. Trad Ronfeldt, architect of the transformation, stripped away the military surplus and began the build from the bare bones of the vehicle. Once installed, a monstrous 600-horsepower Blueprint Engines LS motor emits rumbles of thunder from all 8 cylinders. King shocks are set to soak up the punishment of the unforgiving terrain. KC lights stand ready to pierce the night and the swirling dust of desert storms.<br></span></p>';

  return (
    <NewLayout>
      <SEO page={pageData} />

      {pageData.desktopImages && (
        <div className={styles.hero2024}>
          <HeroCarousel2024
            desktopImages={pageData.desktopImages}
            mobileImages={pageData.mobileImages}
            title={pageTitle}
            onClick={() => scrollFun("gallery")}
            goTo={"gallery"}
          />
        </div>
      )}

      <div className={styles.videoGroup}>
        <p className={styles.videoTitle}>
          {firstSectionContent.title}
        </p>
        <VideoBlock
          id={firstSectionContent.video.id}
          youtubeVideoId={firstSectionContent.video.providerUid}
          image={firstSectionContent.video.thumbnailUrl}
          containerClass={styles.videoBlock}
          imageClass={styles.videoImage}
        />
      </div>

      <div className={cn(styles.semaWrapper, styles.fadeIn)}>
        <div className={cn(styles.seventhSection)}>
          <div className={styles.seventhSection_imageContainer}>
            <Imgix
              src={seventhSection.imageUrl}
              className={
                styles.seventhSection_imageContainer_imageClass
              }
              htmlAttributes={{ alt: '' }}
            />
          </div>
          <div className={styles.contentText}>
            <div
              className={cn(styles.seventhSection_text)}
              dangerouslySetInnerHTML={{
                __html: supSub(tempContent),
              }}
            />
            <Button
              text="READ THE FULL BLOG"
              url="/article/wd40-brand-chase-truck-race-season-begins"
              className={styles.button}
              target="_self"
              red
              rel="noopener noreferrer"
            />
          </div>
        </div>        

        <div className={styles.tableContent}>
          <p className={styles.tableTitle}>
            RACE DATES
          </p>
          <table className={styles.table}>
            <tbody>
              {tableData.map((item, index) => (
                !isMobile ? (<tr key={index}>
                  <td className={styles.tableHeadTD}>{item.date}</td>
                  <td className={styles.tableTD}>{item.name}</td>
                  <td className={styles.tableTD}>{item.location}</td>
                </tr>) :
                  (<tr key={index}>
                    <td className={styles.tableTD}>
                      <p style={{ fontWeight: 'bold' }}>
                        {item.date}
                      </p>
                      <p>
                        {item.name + ' | ' + item.location}
                      </p>
                    </td>
                  </tr>)
              ))}
            </tbody>
          </table>
        </div>

        {partnersData2024 ? (
          <Partners2024
            title={partnersTitle}
            logos={logos}
            isMobile={isMobile}
          />
        ) : (
          false
        )}
        <div id="gallery" style={{ background: '#f6f6f6' }}>
          {galleryData2024 && (
            <GallerySema2024
              data={galleryData2024}
              isMobile={isMobile}
              ctaText={"VIEW THE GALLERY"}
            />
          )}
        </div>
      </div>
      <div 
        className={styles.heroSemaButtonContainer}
      >
        <Button
          text="CHECK OUT THE 2021 SEMA TRUCK BUILD"
          url="/custombuild/1951Chevrolet3100/"
          className={styles.bottomButton}
          target="_self"
          red
          rel="noopener noreferrer"
        />
      </div>

      <CtaBanner
        text="WANT TO GET MORE TIPS AND TRICKS?"
        buttonText="SUBSCRIBE TO THE NEWSLETTER"
        buttonUrl="/signup"
      />
    </NewLayout>
  )
}

export default Sema2024
