import React, { Component } from 'react';
import PropTypes from "prop-types"
import styles from '../styles/scss/components/heroCarousel.module.scss'
import Slider from "react-slick";
import cn from 'classnames';
import {
  isMobile
} from "react-device-detect";
import Title from "./title"

class HeroCarousel2024 extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      speed: 500,
      autoplaySpeed: 7000,
      fade: true,
      arrows: true
    };

    const { title, onClick } = this.props
    const heroDesktopArray = this.props.desktopImages;
    const heroMobileArray = this.props.mobileImages;

    const getTitleParts = (title, part) => {
      return title.split("\n")[part]
    }

    return (
      <Slider {...settings}>

        {isMobile ?
          heroMobileArray.map((item, index) => {
            return (
              // <div key={index}>
              //   <div style={{ backgroundImage: "url(" + item.url + ")" }} className={cn(styles.responsiveImageMobile)} />
              // </div>
              <div key={index} className={styles.heroTitleGroup}>
                <img src={item.url} className={styles.smImages} alt="Page Heros" />
                <div className={styles.heroContent}>
                  <Title className={styles.heroSemaTitle}>
                    {getTitleParts(title, 0)}
                  </Title>
                  <Title className={styles.heroSemaSubTitle}>
                    {getTitleParts(title, 1)}
                  </Title>
                  <div
                    aria-hidden
                    role="button"
                    onKeyDown={onClick}
                    className={styles.heroSemaButtonContainer}
                    onClick={onClick}
                  >
                    <p className={styles.redButton}>VIEW THE GALLERY</p>
                  </div>
                </div>
              </div>
            );
          }) : heroDesktopArray.map((item, index) => {
            return (
              <div key={index} className={styles.heroTitleGroup}>
                <img src={item.url} alt="Page Heros" />
                <div className={styles.heroContent}>
                  <Title className={styles.heroSemaTitle}>
                    {getTitleParts(title, 0)}
                  </Title>
                  <Title className={styles.heroSemaSubTitle}>
                    {getTitleParts(title, 1)}
                  </Title>
                  <div
                    aria-hidden
                    role="button"
                    onKeyDown={onClick}
                    className={styles.heroSemaButtonContainer}
                    onClick={onClick}
                  >
                    <p className={styles.redButton}>VIEW THE GALLERY</p>
                  </div>
                </div>
              </div>
            );
          })}
      </Slider>
    );
  }
}

HeroCarousel2024.propTypes = {
  children: PropTypes.node,
  desktopImages: PropTypes.array,
  mobileImages: PropTypes.array
}

export default HeroCarousel2024
